<head>
    <link href="https://fonts.googleapis.com/css2?family=Bangers&display=swap" rel="stylesheet">
</head>

<div class="menu-mainbar">
    <div class="menu-bar">    
        <div class="menu-bar-title">
            <img class="menu-bar-icon" src="assets/icons/logo.png" alt="WoW Pets Comparator Logo"/>
            <label class="menu-bar-title">{{title}}</label>
        </div>
       
        
        <div class="menu-bar-infos">
            <div class="menu-bar-language">
                <mat-form-field>
                    <mat-label>{{ 'Language' | transloco }}</mat-label>
                <mat-select value='{{currentLangage}}' (selectionChange)="setLang($event.value)">
                    <mat-option value="en_GB">{{ 'English' | transloco }}</mat-option>
                    <mat-option value="fr_FR">{{ 'French' | transloco }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        
        <!--<div class="menu-bar-version">
            <label class="version-label">Version : {{currentVersion}}</label>
            <button class="version-button" mat-icon-button (click)="openVersionDialog()">
                <mat-icon class="version-icon">announcement</mat-icon>
            </button>
            <button class="version-button" mat-icon-button (click)="openContactDialog()">
                <mat-icon class="version-icon">email</mat-icon>
            </button>
        </div>-->
    </div>
    </div>
    <!-- <div class="menu-buttons">
        <button mat-flat-button (click)="goToPets()">{{ 'Pets' | transloco}}</button>
        <button mat-flat-button (click)="goToAchievements()">{{ 'Achievements' | transloco}}</button>
    </div> -->
</div>