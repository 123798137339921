import { Component, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';

@Component({
    selector: 'app-version-dialog',
    templateUrl: './version-dialog.component.html',
    styleUrls: ['./version-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatDialogContent, MatIcon, MatDialogActions, MatButton, MatDialogClose]
})
export class VersionDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
