import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { MatDialog as MatDialog } from "@angular/material/dialog";
import { VersionDialogComponent } from "../version-dialog/version-dialog.component";
import { TranslocoService, TranslocoModule } from "@ngneat/transloco";
import { LanguageService } from "../../services/Language/language.service";
import { Router } from "@angular/router";
import { MatButton } from "@angular/material/button";
import { MatOption } from "@angular/material/core";
import { MatSelect } from "@angular/material/select";
import { MatFormField, MatLabel } from "@angular/material/form-field";

@Component({
    selector: "app-main-title",
    templateUrl: "./main-title.component.html",
    styleUrls: ["./main-title.component.scss"],
    standalone: true,
    imports: [
        MatFormField,
        MatLabel,
        MatSelect,
        MatOption,
        MatButton,
        TranslocoModule,
    ],
})
export class MainTitleComponent implements OnInit {
  title = "World of Warcraft's Pets Comparator";
  currentVersion: string;
  currentLangage: string;

  constructor(
    public dialog: MatDialog,
    private translocoService: TranslocoService,
    private languageService: LanguageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentVersion = environment.version;

    this.languageService
      .getLanguage()
      .subscribe((x) => (this.currentLangage = x));
  }

  openVersionDialog(): void {
    console.log("Ouverture de la fenêtre de version");
    const dialogRef = this.dialog.open(VersionDialogComponent);
  }

  openContactDialog(): void {
    console.log("Ouverture de la fenêtre de contact");
    const dialogRef = this.dialog.open(VersionDialogComponent);
  }

  setLang(value: string) {
    this.translocoService.setActiveLang(value);
    this.languageService.setLanguage(value);
  }

  goToPets() {
    this.router.navigate(["pets-comparator"]);
  }

  goToAchievements() {
    this.router.navigate(["achievements-comparator"]);
  }
}
