import { provideHttpClient } from "@angular/common/http";
import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { provideAnimations } from "@angular/platform-browser/animations";
import { Routes, provideRouter } from "@angular/router";
import { TranslocoRootModule } from "./app/transloco/transloco-root.module";

const routes: Routes = [
    { path: "", redirectTo: "pets-comparator", pathMatch: "full" },
    { path: "pets-comparator", loadComponent: () => import('./app/pets-comparator/pages/pets-comparator/pets-comparator.component').then(mod => mod.PetsComparatorComponent)},
    // {
    //   path: "achievements-comparator",
    //   component: AchievementsComparatorComponent,
    // },
  ];
  
export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(TranslocoRootModule),
        provideAnimations(),
        provideRouter(routes),
        provideHttpClient()
    ]
  };