<h2 mat-dialog-title>Mises à jour par version</h2>
<mat-dialog-content class="mat-typography">
    <div class="version-block">
        <h3>Version 0.5</h3>
        <div class="version-subtitle">
            <mat-icon class="version-icon">bug_report</mat-icon>
            <h4>Corrections</h4>
        </div>        
        <p>Optimisation du chargement des mascottes</p>
    </div>
    <div class="version-block">
        <h3>Version 0.4</h3>
        <div class="version-subtitle">
            <mat-icon class="version-icon">add_circle</mat-icon>
            <h4>Nouveautés</h4>
        </div>
        <p>Gestion de la traduction et traduction Anglaise</p>
        <div class="version-subtitle">
            <mat-icon class="version-icon">bug_report</mat-icon>
            <h4>Corrections</h4>
        </div>        
        <p>Correction et optimisation du chargement des mascottes</p>
    </div>
    <div class="version-block">
        <h3>Version 0.3</h3>
        <div class="version-subtitle">
            <mat-icon class="version-icon">add_circle</mat-icon>
            <h4>Nouveautés</h4>
        </div>
        <p>Visuel adaptatif à la résolution de l'écran</p>
        <div class="version-subtitle">
            <mat-icon class="version-icon">bug_report</mat-icon>
            <h4>Corrections</h4>
        </div>        
        <p>Corrections visuelles (compteur de chargement, centrage,...)</p>
    </div>
    <div class="version-block">
        <h3>Version 0.2</h3>
        <div class="version-subtitle">
            <mat-icon class="version-icon">add_circle</mat-icon>
            <h4>Nouveautés</h4>
        </div>
        <p>Filtres par personnage</p>
        <p>Fenêtre de suivi des mises à jour</p>
        <p>Modification du visuel des totaux de mascottes par personnage</p>
        <div class="version-subtitle">
            <mat-icon class="version-icon">bug_report</mat-icon>
            <h4>Corrections</h4>
        </div>        
        <p>Dans les totaux des mascottes, l'icône pour la source "Promotion" était incorrect.</p>
    </div>
    <div class="version-block">
        <h3>Version 0.1</h3>
        <p>Page de comparaison de mascottes et visuel général du site</p>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Fermer</button>
</mat-dialog-actions>