import { Component, OnInit } from "@angular/core";
import { AuthentificationService } from "./_core/services/Authentification/authentification.service";
import { RouterOutlet } from "@angular/router";
import { MainTitleComponent } from "./_core/components/main-title/main-title.component";
import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import localeEn from "@angular/common/locales/en";
import { LanguageService } from "./_core/services/Language/language.service";
registerLocaleData(localeFr);
registerLocaleData(localeEn);

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    standalone: true,
    imports: [MainTitleComponent, RouterOutlet],    
})
export class AppComponent implements OnInit {
  constructor(private authentService: AuthentificationService, private languageService: LanguageService) { }

  ngOnInit() {
    this.authentService.login();

    const storedLocale = localStorage.getItem("Locale");

    if (storedLocale != undefined) {
      this.languageService.setLanguage(storedLocale);
    } else {
      this.languageService.setLanguage("en_GB");
    }
  }
}
